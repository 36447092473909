'use client';

import { forwardRef, HTMLProps } from 'react';

import classNames from 'classnames';

import { useModalContext } from '@uikit';

import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useMergeRefs,
} from '@floating-ui/react';

const ModalContent = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement> & { overlayClassName?: string; lockScroll?: boolean }
>(({ children, className, overlayClassName, lockScroll, ...props }, forwardedRef) => {
  const {
    context: floatingContext,
    setOpen,
    labelId,
    descriptionId,
    refs,
    getFloatingProps,
    isClosing,
  } = useModalContext();
  const ref = useMergeRefs([refs.setFloating, forwardedRef]);

  if (!floatingContext.open) {
    return null;
  }

  return (
    <FloatingPortal>
      <FloatingOverlay
        lockScroll={lockScroll}
        data-modal-overlay-id={floatingContext.floatingId}
        className={classNames(
          'modal-overlay',
          { 'modal-overlay-closing': isClosing },
          overlayClassName,
        )}
        onClick={(event) => {
          event.stopPropagation();
          setOpen(false);
        }}
      >
        <FloatingFocusManager context={floatingContext}>
          <div
            ref={ref}
            aria-labelledby={labelId}
            aria-describedby={descriptionId}
            {...getFloatingProps(props)}
            onClick={(event) => {
              event.stopPropagation();
            }}
            className={classNames(
              'modal-content',
              { 'modal-content-closing': isClosing },
              className,
            )}
          >
            {children}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
});

export default ModalContent;
