'use client';

import { forwardRef, HTMLProps, useLayoutEffect } from 'react';

import classNames from 'classnames';

import { useModalContext } from '@uikit';

import useUniqueId from '@uikit/utils/hooks/useUniqueId';

const ModalHeading = forwardRef<HTMLHeadingElement, HTMLProps<HTMLHeadingElement>>(
  ({ id, className, ...props }, ref) => {
    const { setLabelId } = useModalContext();
    const modalHeadingId = useUniqueId(id);

    // Only sets `aria-labelledby` on the Modal root element
    // if this component is mounted inside it.
    useLayoutEffect(() => {
      setLabelId(modalHeadingId);
      return () => setLabelId(undefined);
    }, [modalHeadingId, setLabelId]);

    return (
      // eslint-disable-next-line jsx-a11y/heading-has-content
      <h2
        ref={ref}
        id={modalHeadingId}
        className={classNames('modal-heading', className)}
        {...props}
      />
    );
  },
);

export default ModalHeading;
